import Service from "../Service";
const resource = "/exportpallet/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    getExportPalletKG(obj, requestID) {
        return Service.post(resource + "getExportPalletKG", obj, {
            params: { requestID: requestID },
        });
    },


    getprocesspallet(requestID) {
        return Service.post(resource + "getprocesspallet", {}, {
            params: { requestID: requestID },
        });
    },

    GetKgProccessAvailable(obj, requestID) {
        return Service.post(resource + "GetKgProccessAvailable", obj, {
            params: { requestID: requestID },
        });
    },

};